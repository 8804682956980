/** @jsx jsx */

import PropTypes from "prop-types"
import { jsx, Heading } from "theme-ui"

import theme from "../gatsby-plugin-theme-ui"
import { Layout } from "../components/Layout"
import { JournazinePropTypes, ArticlePropTypes } from "../utils/propTypes"
import { extractBaseSeoFields } from "../utils/seoUtils"
import { transformJournazineData } from "../utils/journazineUtils"
import { transformArticlesData } from "../utils/articleUtils"
import { JournazineDetails } from "../components/JournazineDetails"
import { Divider } from "../components/Divider"
import { ItemsGrid } from "../components/ItemsGrid"
import { ArticleBriefCard } from "../components/ArticleBriefCard"
import { DividerType } from "../hooks/divider"
import { BuyPreviewCtaButtons } from "../components/journazine_page/BuyPreviewCtaButtons"

export const JournazinePageTemplate = ({ data }) => {
  const {
    uid,
    cover_image,
    issue_number,
    issue_title,
    issue_description,
    short_issue_description,
    preview_link,
    buy_url,
    sections,
  } = transformJournazineData(data.prismicJournazine)
  const journazineDetailsProps = {
    cover_image,
    issue_number,
    issue_title,
    issue_description,
    preview_link,
    buy_url,
  }

  const seoProps = extractBaseSeoFields({
    type: data.prismicJournazine.type,
    opengraph_image: cover_image,
    meta_title: `${issue_number} - ${issue_title.text}`,
    meta_description: short_issue_description.text,
    uid: uid,
  })

  const sectionNames = sections.map(section => section.section_name)
  const articlesBySection = data.allPrismicArticle.group
    .filter(group => sectionNames.includes(group.journazine_section))
    .sort(
      (a, b) =>
        sectionNames.indexOf(a.journazine_section) -
        sectionNames.indexOf(b.journazine_section)
    )
    .map(group => {
      const articles = transformArticlesData(group)
      return {
        sectionName: group.journazine_section,
        articles,
      }
    })

  return (
    <Layout seoProps={seoProps}>
      <JournazineDetails
        {...journazineDetailsProps}
        otherChildren={<BuyPreviewCtaButtons {...journazineDetailsProps} />}
      />
      <div sx={{ ...theme.basePageStyles.contentContainer }}>
        <Divider
          type={DividerType.SEEDLING}
          styles={{
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: ["80px", "100px", "100px", "150px"],
            mt: ["20px", "20px", "30px", "50px"],
            mb: [null, null, null, "80px"],
          }}
        />
        {articlesBySection.map(section => (
          <div key={section.sectionName} sx={{ mt: 5 }}>
            <Divider
              type={DividerType.DEFAULT_OLIVE}
              styles={{ width: ["60%", "40%", "40%", "30%"] }}
            />
            <Heading as="h3" variant="headingOlive" sx={{ mt: 3, mb: 5 }}>
              {section.sectionName}
            </Heading>
            <ItemsGrid
              items={section.articles}
              component={ArticleBriefCard}
              emphasizeFirst={false}
              itemProps={{ displayBadges: false }}
            />
          </div>
        ))}

        <Divider type={DividerType.DEFAULT_OLIVE} styles={{ my: 3 }} />
        <BuyPreviewCtaButtons
          buy_url={buy_url}
          preview_link={preview_link}
          containerStyles={{ justifyContent: "center" }}
        />
      </div>
    </Layout>
  )
}
JournazinePageTemplate.propTypes = {
  data: PropTypes.shape({
    prismicJournazine: PropTypes.shape(JournazinePropTypes),
    allPrismicArticle: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          journazine_section: PropTypes.string,
          edges: PropTypes.arrayOf(
            PropTypes.shape({
              node: PropTypes.shape(ArticlePropTypes),
            })
          ),
        })
      ),
    }),
  }),
}
