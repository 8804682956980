/** @jsx jsx */

import PropTypes from "prop-types"
import { jsx, Flex } from "theme-ui"

import { Link } from "../../components/Link"

export const BuyPreviewCtaButtons = ({
  preview_link,
  buy_url,
  containerStyles,
}) => (
  <Flex
    sx={{
      justifyContent: ["center", null, null, "flex-start"],
      my: 3,
      ...containerStyles,
    }}
  >
    <Link
      variant="buttonOlive"
      to={buy_url}
      sx={{ ml: 0, px: [3, 3, 4, 5], fontSize: [0, 0, 2, 3] }}
    >
      Buy
    </Link>
    {preview_link.url ? (
      <Link
        variant="buttonOliveTransparent"
        to={preview_link.url}
        sx={{ px: [3, 3, 4, 5], fontSize: [0, 0, 2, 3] }}
      >
        Print Preview
      </Link>
    ) : null}
  </Flex>
)
BuyPreviewCtaButtons.propTypes = {
  preview_link: PropTypes.shape({
    url: PropTypes.string,
  }),
  buy_url: PropTypes.string,
  containerStyles: PropTypes.object,
}
