/** @jsx jsx */

import PropTypes from "prop-types"
import { jsx, Heading, Flex } from "theme-ui"

import theme from "../gatsby-plugin-theme-ui"
import { JournazineDataPropTypes } from "../utils/propTypes"
import { isCrystallizeImage } from "../utils/eCommerceUtils"
import { RichText } from "../components/RichText"
import { ResponsiveImage } from "../components/ResponsiveImage"
import { Divider } from "../components/Divider"
import { DividerType } from "../hooks/divider"

export const JournazineDetails = ({
  cover_image,
  issue_number,
  issue_title,
  issue_description,
  otherChildren,
}) => {
  return (
    <Flex
      sx={{
        flexDirection: ["column", null, null, "row"],
        width: [null, null, null, `${theme.desktopPageContentWidth}%`],
        mx: [null, null, null, "auto"],
      }}
    >
      <ResponsiveImage
        image={cover_image}
        sx={
          isCrystallizeImage(cover_image)
            ? {
                m: 0,
                img: {
                  width: ["100vw", null, null, "525px"],
                  height: "auto",
                },
              }
            : {
                width: [
                  "100vw !important",
                  null,
                  null,
                  `${cover_image.gatsbyImageData.width}px !important`,
                ],
                height: [
                  `calc(100vw * ${cover_image.thumbnails.mobile_portrait.gatsbyImageData.height} / ${cover_image.thumbnails.mobile_portrait.gatsbyImageData.width}) !important`,
                  `calc(100vw * ${cover_image.thumbnails.mobile_landscape.gatsbyImageData.height} / ${cover_image.thumbnails.mobile_landscape.gatsbyImageData.width}) !important`,
                  `calc(100vw * ${cover_image.gatsbyImageData.height} / ${cover_image.gatsbyImageData.width}) !important`,
                  `${cover_image.gatsbyImageData.height}px !important`,
                ],
              }
        }
      />
      <Flex
        sx={{
          ...theme.basePageStyles.contentContainer,
          flex: 1,
          mt: [5, 5, 5, 0],
          ml: ["auto", null, null, "40px"],
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Heading
          as="h3"
          variant="headingOlive"
          sx={{ fontSize: [3, 3, 4], fontWeight: "body" }}
        >
          {issue_number}
        </Heading>
        <Heading
          as="h1"
          variant="headingOlive"
          sx={{ fontSize: [4, 4, 6], mr: "auto" }}
        >
          {issue_title.text}
        </Heading>
        <Divider type={DividerType.DEFAULT_OLIVE} />
        <RichText
          text={issue_description}
          sxOverrides={{ fontSize: [1, 1, 2, 3] }}
        />
        {otherChildren}
      </Flex>
    </Flex>
  )
}
JournazineDetails.propTypes = {
  ...JournazineDataPropTypes,
  otherChildren: PropTypes.node,
}
