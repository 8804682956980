import { graphql } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

import { JournazinePageTemplate } from "./JournazinePageTemplate"
import linkResolver from "../utils/linkResolver"

export const query = graphql`
  query JournazineQuery($id: String, $uid: String) {
    prismicJournazine(id: { eq: $id }) {
      _previewable
      ...Journazine
    }

    allPrismicArticle(
      filter: { data: { journazine_issue: { uid: { eq: $uid } } } }
    ) {
      group(field: data___journazine_section) {
        journazine_section: fieldValue
        edges {
          node {
            ...ArticleCard
          }
        }
      }
    }
  }
`
export default withPrismicPreview(JournazinePageTemplate, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])
